import "../../components/base.css";
import Layout from "../../components/layout";
import React from "react";


export default class Index extends React.Component {
  render() {
    return (
      <Layout>
        <div>
          <br></br>
          <h1>&gt; COMMUNITY TOOLS</h1>
        </div>
        <br></br>
        <div>
          <h2>SHIP BUILD CALCULATOR /</h2>
          <h3>Erkul's ship building <a rel="noreferrer" target="_blank" href="https://www.erkul.games/calculator">site</a></h3>
          <h4>Great site allowing ship customization, item comparison, purchase information, etc</h4>
        </div>
        <br></br>
        <div>
          <h2>TRADE TOOLS AND ROUTES /</h2>
          <h3>Galactic logistics trade <a rel="noreferrer" target="_blank" href="https://www.gallog.co/">tools</a></h3>
          <h4>Everything Trade, Shipping, Routes, Systems, etc.</h4>
        </div>
        <br></br>
        <div>
          <h2>COMMUNITY API /</h2>
          <h3>Fan created API <a rel="noreferrer" target="_blank" href="https://starcitizen-api.com/index.php">resource</a></h3>
          <h4>Pull information straight from the live game data, using this community made API</h4>
        </div>
        <br></br>
        <div>
          <h2>PORTAL SITE /</h2>
          <h3>Starcitizen <a rel="noreferrer" target="_blank" href="https://starcitizen.tools/">tools</a></h3>
          <h4>A competing portal site.  If you went here, I did something wrong</h4>
        </div>
        <br></br>
        <div>
          <h2>TRACKERSC /</h2>
          <h3>Star Citizen updates <a rel="noreferrer" target="_blank" href="https://www.trackersc.com/">tracker</a></h3>
          <h4>I want to pipe this stream into my site</h4>
        </div>
        <br></br>
        <div>
          <h2>DISCORD /</h2>
          <h3>LTG's <a rel="noreferrer" target="_blank" href="https://discord.gg/w3byPMc">discord</a></h3>
          <h4>Can we stream discord?</h4>
        </div>
        <br></br>
      </Layout>
    );
  }
}
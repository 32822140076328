import React from "react";
import Navigation from "./navigation/header";
import "./base.css";

export default ({ children }) => (
  <div className="root">
    <Navigation />
    <div className="main">
      <div className="container">{children}</div>
    </div>
  </div>
);

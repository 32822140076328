import React from "react";
import { Link } from "gatsby";
// import Logo from "./logo";
// import LightDarkToggle from "../LightDarkToggle/LightDarkToggle";
import "./luxbar.css";

const Navigation = () => (
  <header className="header header-default">
    <input type="checkbox" id="header-checkbox" className="header-checkbox" />
    <div className="header-menu header-menu-right header-menu-dark">
      <ul className="header-navigation">
        <li className="header-header">
          <Link className="header-brand" to="/">play!nverted</Link>
          <label
            className="header-hamburger header-hamburger-doublespin"
            htmlFor="header-checkbox"
          >
            <span />
          </label>
        </li>
        {/* <li>
          <LightDarkToggle />
        </li> */}
        <li className="header-item dropdown">
          <Link to="/quick-links/" title="Quick Links">QUICK LINKS</Link>
          <ul>
            <li className="header-item">
              <Link to="/quick-links/rsi-official/">RSI OFFICIAL</Link>
            </li>
            <li className="header-item">
            <Link to="/quick-links/community-tools/">COMMUNITY TOOLS</Link>
            </li>
            <li className="header-item">
            <Link to="/quick-links/streamer-tv/">STREAMER TV</Link>
            </li>            
            <li className="header-item">
            <Link to="/quick-links/how-to/">HOW TO</Link>
            </li>
          </ul>
        </li>
        <li className="header-item">
          <Link to="/buying-guide/" title="Buying Guide">BUYING GUIDE</Link>
        </li>
        <li className="header-item">
        <Link to="/load-outs/" title="Load Outs">LOAD OUTS</Link>
        </li>
        <li className="header-item">
        <Link to="/mine-trade/" title="Mine | Trade">MINE | TRADE</Link>
        </li>
      </ul>
    </div>
  </header>
);

export default Navigation;
